import request from "@/utils/request";

// 各个项目本月安全隐患新增
export function projectAreaCheckpageAll(data: any) {
  return request({
    url: "/project/projectAreaCheck/pageAll",
    method: "get",
    params: data,
  });
}

// 通知我的问题列表
export function projectAreaCheckpageNotice(data: any) {
  return request({
    url: "/project/projectAreaCheck/pageNotice",
    method: "get",
    params: data,
  });
}

// 我创建的问题列表
export function projectAreaCheckpageCreate(data: any) {
  return request({
    url: "/project/projectAreaCheck/pageCreate",
    method: "get",
    params: data,
  });
}

// 我处理的问题列表
export function projectAreaCheckpageDeal(data: any) {
  return request({
    url: "/project/projectAreaCheck/pageDeal",
    method: "get",
    params: data,
  });
}

// 漏检列表
export function projectAreaCheckLogpage(data: any) {
  return request({
    url: "/project/projectAreaCheckLog/page",
    method: "get",
    params: data,
  });
}

// 区域列表
export function projectArealist(data: any) {
  return request({
    url: "/project/projectArea/list",
    method: "get",
    params: data,
  });
}

// 参建单位列表
export function listProjectCompany(data: any) {
  return request({
    url: "/project/projectDept/listProjectCompany",
    method: "get",
    params: data,
  });
}

// 获取区域列表
export function projectAreaType(data: any) {
  return request({
    url: "/project/projectAreaType/list",
    method: "get",
    params: data,
  });
}

// 新增区域
export function projectAreaSave(arg: any, pid: any) {
  return request({
    url: "/project/projectArea/save",
    method: "post",
    data: arg,
    headers: { pId: pid },
  });
}

//保存质量巡检
export function saveCheck(data: any) {
  return request({
    url: "/project/projectAreaCheck/save",
    method: "post",
    data: data,
  });
}

//删除巡检记录
export function projectAreaCheckRemove(data: any) {
  return request({
    url: "/project/projectAreaCheck/remove",
    method: "get",
    params: data,
  });
}

//检查单详情
export function projectAreaHistoryList(id: any) {
  return request({
    url: "/project/projectAreaCheck/getById/" + id,
    method: "get",
  });
}

//漏检详情
export function projectAreaCheckLoggetById(id: any) {
  return request({
    url: "/project/projectAreaCheckLog/getById/" + id,
    method: "get",
  });
}

//保存整改单
export function saveProjectAreaHistory(args: any) {
  return request({
    url: "/project/projectAreaHistory/save",
    method: "post",
    data: args,
  });
}
