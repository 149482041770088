
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() private areaIdscolumns!: any;
  @Prop() private title!: any;
  @Prop() private remark!: any;
  @Prop() private resultid!: any;
  @Prop() private resultname!: any;
  @Prop() private changePersonId!: any;
  @Prop() private changePersonName!: any;

  private showPicker = false;
  private result = "";
  private columns: any[] = [];

  protected openPopup() {
    this.areaIdscolumns.map((item: any) => {
      this.columns.push(item.name);
    });
    this.columns.unshift("新增");
  }

  protected onConfirm(value: any) {
    if (value !== "新增") {
      let index = this.areaIdscolumns.findIndex(
        (item: any) => item.name === value
      );
      this.result = value;
      this.showPicker = false;
      this.$emit("areaConfirm", this.areaIdscolumns[index].id);
    } else {
      let qualitysafe = {
        title: this.title,
        remark: this.remark,
        resultid: this.resultid,
        resultname: this.resultname,
        changePersonId: this.changePersonId,
        changePersonName: this.changePersonName,
      };
      this.$store.commit("project/set_Qualitysafe", qualitysafe);
      this.$router.push("/app/areaAdd");
    }
  }
}
