import request from "@/utils/request";

// 各个项目本月安全隐患新增
export function reportBillCountAllCheck(params: any) {
  return request({
    url: "/project/reportBill/countAllCheck",
    method: "get",
    params: params,
  });
}

// 全部问题
export function mobileReportBillPageAll(params: any) {
  return request({
    url: "/project/reportBill/pageAll",
    method: "get",
    params: params,
  });
}

//获取用户列表
export function pageListCheckUser(data: any) {
  return request({
    url: "/center/sys/user/pageCheckUser",
    method: "get",
    params: data,
  });
}
// 工地列表
export function projectlist() {
  return request({
    url: "/project/project/projectIdAndNameList",
    method: "get",
  });
}

// 检查用语部门列表
export function checkDeptList() {
  return request({
    url: "/project/speechDept/speechDeptList",
    method: "get",
  });
}

// 检查项目列表
export function checkItemList(data: any) {
  return request({
    url: "/project/speechItem/speechItemList",
    method: "get",
    params: data,
  });
}

// 检查部位列表
export function checkAreaList(data: any) {
  return request({
    url: "/project/speechArea/speechAreaList",
    method: "post",
    data: data,
  });
}

// 检查部位列表
export function speechAreaSpeechAreaList(data: any) {
  return request({
    url: "/project/speechArea/speechAreaList",
    method: "post",
    data: data,
  });
}

// 检查部位列表
export function checkInspectionList(data: any) {
  return request({
    url: "/project/speechInspection/speechList",
    method: "post",
    data: data,
  });
}

// 获取用户列表
export function pageListUser(data: any) {
  return request({
    url: "/center/sys/user/pageUser",
    method: "get",
    params: data,
  });
}

// 保存
export function mobileReportBillSave(data: any) {
  return request({
    url: "/project/reportBill/save",
    method: "post",
    data: data,
  });
}

//检查单详情
export function mobileReportBillgetById(data: any) {
  return request({
    url: "/project/reportBill/getById",
    method: "get",
    params: data,
  });
}

// 更新检查单
export function mobileReportRectifyUpdate(data: any) {
  return request({
    url: "/project/reportBillRectify/update",
    method: "post",
    data: data,
  });
}

// 获取区域列表
export function projectAreaType(params: any) {
  return request({
    url: "/project/projectAreaType/list",
    method: "get",
    params: params,
  });
}
